import React, { useState, useCallback } from "react";
import { GoogleMap, Marker, InfoWindow, LoadScript } from "@react-google-maps/api";
import * as locationData from "./locations.json";
import mapStyle from "./mapStyles.js";
import styled from 'styled-components'

const libraries = ["places"];

const mapContainerStyle = {
    height: '60vh',
    width: '50vw',
    border: '3px ridge #003367'
};

const dmv = {
    lat: 38.9072,
    lng: -77.0369
};

const options = {
    styles: mapStyle,
    disableDefaultUI: true,
    zoomControl: true
}

const Location = styled.div`
`;

const {REACT_APP_MAPS_API_KEY} = process.env;

const MyGoogleMap = ({selected, onSelectedLocationChange}) => {
    const [map, setMap] = useState(null)
    
    const onLoad = useCallback((map) => {
        setMap(map)
    }, [])
    
    const onUnmount = useCallback((map) => {
        setMap(null)
    }, [])
    
    return(
        <LoadScript googleMapsApiKey={REACT_APP_MAPS_API_KEY}>
        <GoogleMap 
            mapContainerStyle={mapContainerStyle}  
            zoom={selected === null ? 11 : 13 } 
            center={selected === null ? dmv : selected.coords}
            onLoad={onLoad}
            onUnmount={onUnmount}
            options={options}
            libraries={libraries}
        > 
        
        {locationData.locations.map((location, index) => (
            <Location key={index}>
            <Marker 
                position={location.coords}
                onClick={() => {
                    onSelectedLocationChange(location);           
                }}
            />
            {selected === location ? 
            (<InfoWindow
                position={location.coords}
                onCloseClick={() => {onSelectedLocationChange(null)}}
            >
                <h5>{location.name}</h5>
            </InfoWindow>) : null }
            
            </Location>
        ))}
        </GoogleMap>
        </LoadScript>
    )
    
    
}

export default MyGoogleMap;