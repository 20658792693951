export const primary = {
    100: "#CAFDF8",
    200: "#96FBF9",
    300: "#61EBF3",
    400: "#39D1E7",
    500: "#00ACD8",
    600: "#0086B9",
    700: "#00649B",
    800: "#00477D",
    900: "#003367",
    1: "linear-gradient(180deg, #96FBF9 0%, #39D1E7 100%)"
};

export const info = {
    100: "#D8DDFF",
    200: "#B2BAFF",
    300: "#8B97FF",
    400: "#6F7CFF",
    500: "#3F4FFF",
    600: "#2E3ADB",
    700: "#1F29B7",
    800: "#141B93",
    900: "#0C117A",
}

export const success = {
    100: "#E0FCD6",
    200: "#BCFAAF",
    300: "#8DF184",
    400: "#63E363",
    500: "#34D143",
    600: "#26B340",
    700: "#1A963C",
    800: "#107937",
    900: "#096433",
};

export const warning = {
    100: "#FEFCCC",
    200: "#FEF99A",
    300: "#FCF467",
    400: "#FAEF41",
    500: "#F7E704",
    600: "#D4C502",
    700: "#B1A302",
    800: "#8F8201",
    900: "#766B00",
};

export const danger = {
    100: "#FFE3D7",
    200: "#FFC1B0",
    300: "#FF9788",
    400: "#FF706B",
    500: "#FF3A44",
    600: "#DB2A43",
    700: "#B71D40",
    800: "#93123B",
    900: "#7A0B38",
}
