import React from 'react';

function Footer() {
    return(
        <footer>
           <p>Footer copywright text belongs here</p> 
        </footer>
    )

}
export default Footer;