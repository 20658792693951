import PrimaryInput from "./InputFields"
import IconButton from "./Buttons"
import styled from "styled-components"
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'


const StyledSearchBox = styled.div`
    display: flex;
    justify-content: space-between;
`

const SearchBox = ({placeholder, size}) => {
    return(
        <StyledSearchBox>
            <PrimaryInput type="text" placeholder={placeholder} modifiers={size}></PrimaryInput>
            <IconButton><FontAwesomeIcon icon={faSearch}/></IconButton>
        </StyledSearchBox>
    )
}

export default SearchBox;