import styled from "styled-components"
import {applyStyleModifiers} from 'styled-components-modifiers'

const INPUT_MODIFIERS = {
    small: () => `
        font-size: 0.9em;
        width: 150px;
        height: 40px;
    `,
    medium: () => `
        font-size: 1.25em;
        width: 300px;
        height: 60px;  
    `,
    large: () => `
        font-size: 1.5em;
        width: 450px;
        height: 68px;
    `  
}
export const PrimaryInput = styled.input`
    height: 75px;
    margin: 6px 0;
    background: white;
    padding: 6px 12px;
    border: 3px solid #39D1E7;
    border-image: linear-gradient(180deg, #96FBF9 0%, #39D1E7 100%);
    border-image-slice: 1;
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.2);
    font-size: 18px;

    &:focus{
        box-shadow: 0 0 20px #39D1E7;
        border: 3px solid #003367;
        border-image: linear-gradient(180deg, #00447D 0%, #003367 100%);
        border-image-slice: 1;
        outline: none;
    }
    ${applyStyleModifiers(INPUT_MODIFIERS)};
`

export default PrimaryInput;