import React from "react";
import {Modal, PrimaryButton, SecondaryButton, TertiaryButton, PrimaryInput} from "../components"
import styled from 'styled-components'

const StyledSignInForm = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;
const SignInForm = () => {
    return(
        <StyledSignInForm>
                <PrimaryInput modifiers="medium" type="text" placeholder='username'/>
                <PrimaryInput modifiers="medium" type="text" placeholder='password'/>
                <TertiaryButton>Forgot Username or Password?</TertiaryButton>
                <PrimaryButton>Sign In</PrimaryButton>
        </StyledSignInForm>
    )
}
const SignInModal = () => {
    return(
        <Modal heading="welcome back!" subheading="please sign in" width="500px">
            <SignInForm>
            </SignInForm>  
        </Modal>
    )
}
export default SignInModal;