import React from "react";
import Nav from "./components/Nav.js";
import Footer from "./components/Footer.js";
import Home from "./Home/Home.js"
import About from './About/About.js';
import Locations from './Locations/Locations.js'
import Contact from './Contact/Contact.js'
import SignIn from './SignIn/SignIn'
import SignUp from "./SignUp/SignUp.js"
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import GreenEffect from "./GreenEffect/GreenEffect.js";
import {GlobalStyle} from "./utils"
import styled from 'styled-components'


function App() {
    return(
        <Router>
            <GlobalStyle></GlobalStyle> 
                <Nav></Nav>
                <Switch>
                    <Route path="/" exact component={Home}/>
                    <Route path="/about" component={About}/>
                    <Route path="/greeneffect" component={GreenEffect}/>
                    <Route path="/locations" component={Locations}/>
                    <Route path="/contact" component={Contact}/>
                    <Route path="/signin" component={SignIn}/>
                    <Route path="/signup" component={SignUp}/>
                </Switch>
        </Router>
    )
}
export default App;