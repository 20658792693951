import styled from 'styled-components'

export const Card = styled.div`
    background: rgba(255, 255, 255, 0.8);
    width: ${props => props.width};
    color: ${props => props.textColor};
    box-shadow: 0 0.25em 0.15em #00346777;
    padding: 3em;
    display: flex;
    flex-flow: column;
    align-items: center;
`
export const CardImage = styled.img`
    width: 100px;
    height: 100px;
`

export const FlipCard = styled(Card)`
`


export default Card;