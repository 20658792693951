import styled from 'styled-components'
import {applyStyleModifiers} from 'styled-components-modifiers'
import {defaultTheme, typeScale} from "../utils"

const BUTTON_MODIFIERS = {
    small: () => `
        font-size: 0.9em;
        width: 80px;
        height: 40px;
    `,
    medium: () => `
        font-size: 1.25em;
        width: 150px;
        height: 60px;  
    `,
    large: () => `
    font-size: 1.5em;
    width: 400px;
    height: 100px;  
`
}
const Button = styled.button`
    display: flex;
    justify-content: center;
    cursor: pointer;
    align-items: center;
    padding: 12px 24px;
    font-size: ${typeScale.p};
    min-width: 100px;
    font-family: 'Montserrat', sans-serif;
    text-transform: lowercase;
    color: ${defaultTheme.textColor};
`;

export const PrimaryButton = styled(Button)`
    border: none;
    background: ${defaultTheme.primaryColor};
    box-shadow: 0 0.25em 0.25em rgba(0, 0, 0, 0.2);
    transition: 0.25s ease-in-out, background-color 0.25s linear, color 0.25s linear;

    &:hover{
        background: #00ACD8;
        box-shadow: 0 0.5em 0.5em -0.4em #00ACD8;
        transform: translateY(-0.25em);
        color: white;
    }

    &:active {
        box-shadow: 0 0.25em 0.25em inset #003467b4;
    }

    &:focus {
        box-shadow: 0 0 0.75em #00649B;
        outline: none;
    }
    ${applyStyleModifiers(BUTTON_MODIFIERS)};
`;

export const SecondaryButton = styled(Button)`
    background: transparent;
    border: 3px solid;
    border-image: ${defaultTheme.primaryColor};
    border-image-slice: 1;
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.2);
    text-decoration: none;
    transition: 0.25s ease-in-out;
    color: #96FBF9;

    &:hover {
        border-color: #00ACD8;
        box-shadow: 0 0.5em 0.5em -0.4em #00ACD8;
        transform: translateY(-0.25em);
        color: #00ACD8;
    }
    &:focus {
        box-shadow: 0 0 0.75em #00649B;
        outline: none;
    }
    &:active {
        box-shadow: 0 0.25em 0.25em inset #003467b4;
    }
    ${applyStyleModifiers(BUTTON_MODIFIERS)};
`;

export const TertiaryButton = styled(Button)`
    background: transparent;
    border: none;
    text-decoration: underline;
    display: inline-flex;
`;

export const IconButton = styled.button`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(180deg, #96FBF9 0%, #39D1E7 100%);
    border-radius: 50%;
    border: none;
    box-shadow: 0 0.25em 0.15em #00346777;
    transition: 0.5s translateY(-0.5em);
`;

export default PrimaryButton;