import React, { useState } from 'react';
import MyGoogleMap from './MyGoogleMap.js';
import LocationInfo from "./LocationInfo.js"
import {MainWrapper, SearchBox, MainHeading, ComingSoon} from "../components"
import styled from "styled-components"
import bgImg from "./Images/bg.png"
const StyledLocationsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 10%;
`

const StyledListContainer = styled.div`
    width: 35%;
`

const Locations = () => {
    const [selected, setSelectedLocation] = useState(null);
    
    const changeSelectedLocation = (newLocation) => {
        setSelectedLocation(newLocation);
    }
    return(
        <MainWrapper bgImg={bgImg} comingSoon>
            <MainHeading>Find Us!</MainHeading>   
            <StyledLocationsContainer>
                <StyledListContainer>
                    <SearchBox placeholder="Enter your location here" size="large"></SearchBox>
                    <LocationInfo selected={selected} onSelectedLocationChange={changeSelectedLocation}> </LocationInfo>
                </StyledListContainer>
                
                <MyGoogleMap selected={selected} onSelectedLocationChange={changeSelectedLocation}></MyGoogleMap>
            </StyledLocationsContainer>
        </MainWrapper>
    )
}


export default Locations;