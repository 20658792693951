import React from "react";
import {Modal, PrimaryButton, SecondaryButton, TertiaryButton, PrimaryInput} from "../components"
import styled from 'styled-components'

const StyledSignUpForm = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;
const Row = styled.div`
    display: inline-flex;
    gap: 5%;
`;

const SignUpForm = () => {
    return(
        <StyledSignUpForm>
                <Row>
                    <PrimaryInput modifiers="medium" type="text" placeholder='First Name'/>
                    <PrimaryInput modifiers="medium" type="text" placeholder='Last Name'/>
                </Row>
                <PrimaryInput modifiers="medium" type="text" placeholder='Username'/>
                <PrimaryInput modifiers="medium" type="text" placeholder='Password'/>
                <PrimaryInput modifiers="medium" type="text" placeholder='Repeat Password'/>
                <TertiaryButton>Already have an account? Sign in here!</TertiaryButton>
                <PrimaryButton>Sign Up!</PrimaryButton>
        </StyledSignUpForm>
    )
}


const SignUpModal = () => {
    return(
        <Modal heading="Welcome to Stanley Auto Spa!" subheading="Please Register Here" width="700px">
            <SignUpForm></SignUpForm>
        </Modal>
    )
}
export default SignUpModal;