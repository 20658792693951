import React from 'react';
import SignInModal from "./SignInModal"
import {MainHeading, MainWrapper} from "../components"
import bgImg from "./Images/bg.png"
const SignIn = () => {
    return(
        <MainWrapper bgImg={bgImg} comingSoon>
            <MainHeading>Sign in</MainHeading>
            <SignInModal />
        </MainWrapper>
    )
}
export default SignIn;