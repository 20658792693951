import React from 'react'
import styled from 'styled-components'
import {primary, secondaryFont} from "../utils"

const requirementsData = [
    {
        title: "40amp Circuit",
        img: "images/graphics/electricity.png"
    },
    {
        title: "Water Connection",
        img: "images/graphics/waterconnection.png"
    },
    {
        title: "4 Parking Spots",
        img: "images/graphics/parking.png"
    }
]


const StyledRequirementsSection = styled.section`
    position: absolute;
    background: ${primary[900]};
    color: ${primary[100]};
    text-align: center;
    padding: 20px 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 100vw;
`;

const RequirementsHeader = styled.h2`
    grid-column: 1 / -1;
    font-size: 3rem;
`;

const StyledRequirement = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const RequirementTitle = styled.h3`
    font-family: ${secondaryFont};
`;

const RequirementGraphic = styled.img`
    width: 350px;
    height: 350px;
`;

const Requirement = ({title, img}) => {
    return(
        <StyledRequirement>
            <RequirementTitle>{title}</RequirementTitle>
            <RequirementGraphic src={img}></RequirementGraphic>
        </StyledRequirement>

    )
}

const Requirements = () => {
    return(
        <StyledRequirementsSection>
            <RequirementsHeader>Community Requirements</RequirementsHeader>
            {
                requirementsData.map((requirement) => (
                    <Requirement title={requirement.title} img={requirement.img}/>
                ))
            }
        </StyledRequirementsSection>
    )
}

export default Requirements;