import React from 'react';
import MainWrapper from '../components/Wrappers.js'
import {Slider} from "../components"
import styled from 'styled-components'
import Requirements from "./Requirements"
import Benefits from "./Benefits"
import sliderImages from "./SliderImages"

const BasicInfo = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
`
const VideoSection = styled.video`
    position: relative;
    width: 100vw;
`;

const About = () => {
    return(
        <div>
            <Slider slides={sliderImages}>
            </Slider>
            <VideoSection controls>
                <source src="videos/stanleyauto.mp4" type="video/mp4"></source>
            </VideoSection>
            <Benefits/>
            <Requirements />
        </div>
    )
}


export default About;