import React, {useState} from 'react';
import styled from 'styled-components'
import {primary} from '../utils'
import NavLogo from "./NavLogo"
import {NavLink} from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faTimes  } from '@fortawesome/free-solid-svg-icons'

const activeClassName = 'nav-item-active'

const StyledNav = styled.nav`
    position: fixed;
    width: 100%;
    display: flex;
    flex-flow: row;
    align-content: center;
    justify-content: space-between;
    background-color: ${props => props.navbar ? "white" : "transparent"};
    height: 100px;
    z-index: 3;
`

const StyledList = styled.ul`
    justify-self: flex-end;
    align-self: center;
    
    @media screen and (max-width: 1258px) {
        display: none;
    }
`

const StyledLink = styled(NavLink).attrs({ activeClassName })`
    display: inline-block;
    margin: 0.5em 1.5em;
    font-size: 1em;
    font-weight: bold;
    padding: 1rem;
    text-transform: uppercase;
    text-decoration: none;
    text-shadow: 0px 4px 3px rgba(0,0,0,0.4),
             0px 8px 13px rgba(0,0,0,0.1),
             0px 18px 23px rgba(0,0,0,0.1);
    transition: 0.25s ease-in-out;
    cursor: pointer;
    color: ${props => props.inverted ? primary[400] : "white"};
    
    &:hover{
        color: ${props => props.inverted ? primary[500] : primary[900]};
        text-shadow: 0 0.7em 0.7em rgba(0,0,0,0.5);
        transform: translateY(-0.25em);
    }

    &.${activeClassName} {
        border-bottom: 3px solid ${props => props.inverted ? primary[500] : primary[200]};
        color: ${props => props.inverted ? primary[500] : primary[200]};
    }
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
    display: none;
    color: ${primary[900]};

    @media screen and (max-width: 1258px) {
        display: block;
        cursor: pointer;
        align-self: center;
        transform: translate(-50%, 25%);
    }

`;

const NavLinksData = [

    {
        to: "/about",
        text: "About"
    },
    {
        to: "/greeneffect",
        text: "The Green Effect"
    },
    {
        to: "/locations",
        text: "Locations"
    },
    {
        to: "/contact",
        text: "Contact"
    },
    {
        to: "/signin",
        text: "Sign In"
    },
    {
        to: "/signup",
        text: "Sign Up"
    }
]


const Nav = () => {
    const [navbar, setNavbar] = useState(false);
    const [mobileNav, setMobileNav] = useState(false);

    const changeBackground = () => {
        if(window.scrollY >= 100) {
            setNavbar(true);
        } else {
            setNavbar(false);
        }
    }

    window.addEventListener('scroll', changeBackground);


    return(
        <StyledNav navbar={navbar}>
            <NavLogo></NavLogo>
            <StyledList>
                <StyledLink to='/' exact inverted={navbar}>Home</StyledLink>
                {
                    NavLinksData.map((item, index) => (
                        
                        <StyledLink to={item.to} inverted={navbar}>{item.text}</StyledLink>
                    
                    ))
                }
            </StyledList>
            <StyledFontAwesomeIcon icon={faBars} size="2x"></StyledFontAwesomeIcon> 
        </StyledNav>
    )
}

export default Nav;