import React from 'react'
import styled from 'styled-components'
import {primary} from '../utils'

const PageWrapper = styled.div`
    position: relative;
    width: 100vw;
    height: 100vh;
`;

const ContentWrapper = styled.div`
    position: absolute;
    top: 20%;
    padding: 0 5%;
`

const MainHeading = styled.h1`
    position: absolute;
`

const StyledComingSoon = styled.div`
    position: absolute;
    top: 100px;
    height: calc(100vh - 100px);
    opacity: 80%;
    background-color: ${primary[100]};
    color: ${primary[900]};
    font-size: 5em;
    text-align: center;
    width: 100%;
    z-index: 2;
`
const BackgroundImage = styled.img`
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 0%;
    left: 0%;
    z-index: -1;
    width: 100vw;
    height: 100vh;
    opacity: 80%;
`;

const ComingSoonText = styled.h1`
    font-size: 6rem;
`;
const ComingSoon = () => {
    return(
        <StyledComingSoon>
            <ComingSoonText>COMING SOON</ComingSoonText>
            <img src="images/logos/mainlogo-medium.png"></img>
        </StyledComingSoon>
    )
}
export const MainWrapper = ({comingSoon, bgImg, children}) => {
    return(
        <PageWrapper>
            <BackgroundImage src={bgImg}></BackgroundImage>
            {
                comingSoon ? <ComingSoon></ComingSoon> : "" 
            
            }
            <ContentWrapper>{children}</ContentWrapper>
            
        </PageWrapper>
    )
}
export default MainWrapper;