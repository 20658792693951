import {createGlobalStyle} from "styled-components"
import {primaryFont, typeScale, secondaryFont} from "./typography"
import {primary} from "./colors"
import {normalize} from "polished"

export const GlobalStyle = createGlobalStyle`
    ${normalize()}
    html {
        box-sizing: border-box;
        font-size: 16px;
        margin: 0;
        padding: 0;
        color: ${primary[200]};
        overflow-x: hidden;
    }
    *, *:before, *:after {
        box-sizing: inherit;
    } 
    body {
        font-family: ${primaryFont};
    }

    h1 {
        font-size: ${typeScale.h1};
    }

    h2 {
        font-size: ${typeScale.h2};

    }

    h3 {
        font-size: ${typeScale.h3};
    }

    h4{
        font-size: ${typeScale.h4};
    }

    h5{
        font-size: ${typeScale.h5};

    }

    p{
        font-size: ${typeScale.p};
        font-family: ${secondaryFont}
    }
`