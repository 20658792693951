import React from 'react';
import {StyledLink, MainWrapper, PrimaryButton, SecondaryButton, SubHeading, MainHeading, Slider} from "../components"
import styled from 'styled-components'
import bgImg from "./Images/homebg.png"

const StyledHomeContent = styled.div`
    display: grid;
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 24px;
`
const ActionButtons = styled.div`
    display: flex;
    flex-flow: row wrap;
    gap: 5%;
`

const fontColor = "white";

const Home = () => {
    return (
            <MainWrapper bgImg={bgImg} font-color={fontColor}>
                <StyledHomeContent>
                    <MainHeading>Your new, on site auto spa</MainHeading>
                    <SubHeading>Stanley Auto Spa offers the best, on site, spa services for your car and pet (coming soon).  Simply pull your car onto the patent pending wash pad, start the self-serve wash system with the QR code from the Stanley Auto Spa app or with your credit card and start pampering your automobile.  With options like extra suds, rinse, or a glow wax, your auto is sure to be the prettiest car on the road.  And yes, there is more!  All of the water that you use will be contained in our patent pending wash pad, pumped into a recycling chamber, and the filtered and recycled water will be used for future wash cycles and to water the garden chamber that is attached to the wash spa.</SubHeading>
                    <ActionButtons>
                        <StyledLink to='/signup'>
                            <PrimaryButton modifiers="large">Get Started</PrimaryButton>
                        </StyledLink>
                        <StyledLink to='/about'>
                            <SecondaryButton modifiers="large">Learn More</SecondaryButton>
                        </StyledLink>
                    </ActionButtons>
                    
                </StyledHomeContent>
            </MainWrapper>
    )
}

export default Home;