import React from 'react';
import SignUpModal from "./SignUpModal"
import {MainHeading, MainWrapper} from "../components"
import bgImg from "./Images/bg.png"
const SignUp = () => {
    return(
        <MainWrapper comingSoon bgImg={bgImg}>
            <MainHeading>Sign Up</MainHeading>
            <SignUpModal />
        </MainWrapper>
            
    )
}
export default SignUp;