import React from 'react';
import Card, {CardImage} from "../components/Card.js"
import primary from "../utils"
import styled from 'styled-components'

const ContactLink = styled.a`
    color: #0086B9;
`

const ContactCard = ({img, header, descrip, href, contact}) => {
    return(
        <Card width="35%" textColor="#003367">
            <CardImage src={img}></CardImage>
            <h2>{header}</h2>
            <p>{descrip}</p>
            <ContactLink href={href}>{contact}</ContactLink>
        </Card>
    )
}

export default ContactCard;