import React, { useState, useRef } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronCircleRight, faChevronCircleLeft  } from '@fortawesome/free-solid-svg-icons'
import {primary} from "../utils"
import {MainHeading, SubHeading} from "./PageHeadings"

const SliderSection = styled.section`
    height: 100vh;
    position: relative;
    overflow: hidden;
`;

const SliderWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-self: center;
    align-items: center;
    overflow: hidden;
    position: relative;
`;

const Slide = styled.div`
    z-index: 1;
    width: 100%;
    height: 100%;
`;

const SliderContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

`;

const SliderImage = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    opacity: 50%;
    &::before {
        content: '';
        position: absolute;
        z-index: 2;
        width: 100%;
        height: 100vh;
        bottom: 0vh;
        overflow: hidden;
        opacity: 0.4;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 50%, rgba(0, 0, 0, 0.6) 100%);


    }
`;

const SliderContent = styled.div`
    position: relative;
    z-index: 6;
    display: flex;
    flex-direction: column;
    max-width: 90%;
    width: 80%;

`;

const SliderButtons = styled.div`
    position: absolute;
    display: flex;
    z-index: 5;
    bottom: 5%;
    left: 50%;
`;

const Arrow = styled(FontAwesomeIcon)`
    color: ${primary[700]};
    cursor: pointer;
    transition: 0.3s;

    &:hover {
        transform: scale(1.05);
        color: ${primary[500]};
    }

    &:active{
        color: ${primary[300]};
    }
`;

const ArrowButton = styled.button`
    background: transparent;
    border: none;
    outline: none;
    &:active{
        outline: none;
    }
`;
const NextArrow = ({onClick}) => {
    return(
        <ArrowButton onClick={onClick}>
            <Arrow icon={faChevronCircleRight} size='4x'></Arrow>
        </ArrowButton>
    )
}

const PrevArrow = ({onClick}) => {
    return(
        <ArrowButton onClick={onClick}>
            <Arrow icon={faChevronCircleLeft} size='4x'></Arrow>
        </ArrowButton>
    )
}


const Slider = ({slides}) => {
    const [current, setCurrent] = useState(0);
    const last = slides.length - 1;

    const timeout = useRef(null);

    const nextSlide = () => {
        setCurrent(current === last ? 0 : current + 1);
        console.log(current);
    }

    const prevSlide = () => {
        setCurrent(current === 0 ? last : current - 1);
        console.log(current);
    }

    return(
        <SliderSection>
            <SliderWrapper>
            {
                slides.map((slide, index) => {
                    return(
                        <Slide key={index}>
                            {
                                index === current && (
                                    <SliderContainer>
                                        <SliderImage src={slide.img}></SliderImage>
                                        <SliderContent>
                                            <MainHeading>{slide.heading}</MainHeading>
                                            <SubHeading>{slide.subheading}</SubHeading>
                                        </SliderContent>
                                    </SliderContainer>
                                )
                            }
                            
                        </Slide>
                    );
                })
            }

            <SliderButtons>
                <PrevArrow onClick={prevSlide}/>
                <NextArrow onClick={nextSlide}/>
            </SliderButtons>

            </SliderWrapper>
        </SliderSection>

    )
}

export default Slider;