export const primaryFont = '"Overpass", sans-serif';
export const secondaryFont='"Montserrat", sans-serif';

export const typeScale = {
    h1:         "1.8rem",
    h2:         "1.6rem",
    h3:         "1.4rem",
    h4:         "1.2rem",
    h5:         "1.1rem",
    p:          "1rem",
    helper:     "0.8rem",
    copyright:  "0.7rem"
}