import React from 'react';
import ContactCard from './ContactCard';
import MainHeading, {SubHeading} from "../components/PageHeadings"
import MainWrapper from "../components/Wrappers"
import styled from "styled-components"
import bgImg from "./Images/bg.png"

const ContactMethods = styled.div`
    display: flex;
    gap: 5%;
`
const Contact = () => {
    return (
        <MainWrapper bgImg={bgImg}>
            <MainHeading>Get In Touch!</MainHeading>
            <SubHeading>For Inquiries, service, or to find out how you can get Stanley Auto Spa at your location, please email daryle@stanleyautospa.com or call (301) 343-0301.</SubHeading>    
            <ContactMethods>
                <ContactCard img="images/graphics/phone.png" header="Give us a call!" descrip="Daryle Bobb" href="tel:+3013430301" contact="(301)-343-0301"></ContactCard>
                <ContactCard img="images/graphics/mail.png" header="Send us an email!" descrip="daryle@stanleyautospa.com" href="mailto:daryle@stanleyautospa.com" contact="Click here to send a message"></ContactCard>
            </ContactMethods>
        </MainWrapper>
    )
}
export default Contact;
