import styled from 'styled-components'
import {primary, secondaryFont} from "../utils"

export const MainHeading = styled.h1`
    color: ${primary[200]};
    font-size: 4rem;
    text-shadow: 0 0 0.5rem rgba(0,0,0,0.4);
`

export const SubHeading = styled.h3`
    color: ${primary[100]};
    font-family: ${secondaryFont};
    text-shadow: 0 0 0.5rem rgba(0,0,0,0.4);
`

export default MainHeading;