import React from 'react';
import * as locationsList from './locations.json'
import styled from "styled-components"

const StyledLocationsList = styled.ul`
    list-style-type: none;
    align-self: flex-start;
    width: 100%;
    padding: 0px;
`

const LocationItem = styled.li`
    margin-left: 0px;
    background: linear-gradient(0deg, rgba(150,251,249,1) 0%, rgba(225,255,254,1) 100%);
    border: 2px solid ${props => props.active ? "#003367" : "white" };
    text-align: ${props => props.active ? "center" : "left"};
    &:hover{
        background:  rgba(150,251,249,1);
        border: 1px ridge #003367;
        cursor: pointer; 
    }
`

const LocationItemActive = styled(LocationItem)`
    border: 1px ridge #003367;
    text-align: center;
`

const StyledLocationDetails = styled.div`
    padding-left: 12px;
`
const LocationTitle = styled.h3`
    text-decoration: underline;
`

const LocationDetails = ({location}) => {
    return(
        <StyledLocationDetails>
            <LocationTitle>{location.name}</LocationTitle>
            <h5>{location.address.num} {location.address.streetName} {location.address.streetSuffix}</h5>    
            <h5>{location.address.city}, {location.address.state} {location.address.zip}</h5>
        </StyledLocationDetails>
    )
}
const LocationInfo = ({selected, onSelectedLocationChange}) => {
    return(
        <StyledLocationsList>
            {locationsList.locations.map( (location) => (
                
                <LocationItem 
                    key={location.id} 
                    active={selected && selected.id === location.id}
                    onClick={() => {
                        onSelectedLocationChange(location)
                    }}
                >

                    <LocationDetails location={location}></LocationDetails>
                </LocationItem>  
            ))}
        </StyledLocationsList>
    )
}

export default LocationInfo;
