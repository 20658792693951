import styled from 'styled-components'
import React from 'react'
import {defaultTheme, typeScale} from "../utils"

const StyledModal = styled.div`
    text-align: center;
    border: 3px ridge #003367;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: rgba(255, 255, 255, 0.8);
    width: ${props => props.width};
`

const ModalHeading = styled.h2`
    color: ${defaultTheme.primaryColor};
    text-align: center;
    margin-bottom: 0;
    font-size: ${typeScale.h2};
`

const ModalSubHeading = styled.h4`
    text-align: center;
    margin-bottom: 0px;
`
const ModalContent = styled.div`
    margin: 5%;
`

const Modal = ({heading, subheading, width, children}) => {
    return(
        <StyledModal width={width}>
            <ModalHeading>{heading}</ModalHeading>
            <ModalSubHeading>{subheading}</ModalSubHeading>
            <ModalContent>{children}</ModalContent>
        </StyledModal>
        
    );
}

export default Modal;