import imgOne from "./Images/one.jpg"
import imgTwo from "./Images/two.jpg"
import imgThree from "./Images/three.jpg"

const sliderImages = [
    {
        img: imgOne,
        heading: "Meet Stanley!",
        subheading: "Stanley will be covered to provide shade and it is large enough to accommodate two cars to be washed simultaneously."
    },
    {
        img: imgTwo,
        heading: "How It Works",
        subheading: "Stanley comes with a drive-up ramp for residents to pull their cars onto and allows them to wash and vacuum their cars."
    },
    {
        img: imgThree,
        heading: "The Stanley App",
        subheading: "Payment for Stanley will be through an app. Neither cash nor coins will be accepted by Stanley."
    }
]

export default sliderImages;