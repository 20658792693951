import React from 'react'
import styled from 'styled-components'
import {primary, secondaryFont} from "../utils"


const benefitsData = [
    {
        title: "Bonus Amenity",
        img: "images/graphics/amenity.png",
        subtitle: "Tremendous amenity to offer residents",
        benefits: ['Can market Stanley as an "On-Site Car Wash Facility"']
    },
    {
        title: "The Stanley App",
        img: "images/graphics/stanleyapp.png",
        subtitle: "Access to the Stanley App to provide residents with free car washes through your partner portal",
        benefits: ['Provide free car washes for extended lease terms, on time payments, etc...', "Potential ‘Slush Fund’ revenue through commission based on gross receipts"]
    },

]

const BenefitsHeader = styled.h2`
    font-size: 3rem;
`;
const BenefitsSection = styled.section`
    position: relative;
    text-align: center;
    color: ${primary[900]};
    background-color: ${primary[100]};
    display: grid;
`;

const StyledBenefit = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 2fr;
    align-items: center;
`;

const BenefitGraphic = styled.img`
    width: 350px;
    height: 350px;
`;
const StyledBenefitsList = styled.ul`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    list-style-type: none;
`;

const StyledListItem = styled.li`
    font-family: ${secondaryFont};
`

const BenefitDetails = ({heading, list}) => {
    return(
        <StyledBenefitsList>
            <h3>{heading}</h3>
            {
                list.map((item) => (
                    <StyledListItem>{item}</StyledListItem>
                ))
            }
        </StyledBenefitsList>
    )
}

const Benefit = ({title, img, subtitle, benefits}) => {
    return(
        <StyledBenefit>
            <h2>{title}</h2>
            <BenefitGraphic src={img}/>
            <BenefitDetails heading={subtitle} list={benefits}/>
        </StyledBenefit>
    )
}
const Benefits = () => {
    return(
        <BenefitsSection>
            <BenefitsHeader>How Stanley Can Benefit Your Property</BenefitsHeader>
            {
                benefitsData.map((benefit) => (
                    <Benefit title={benefit.title} img={benefit.img} subtitle={benefit.subtitle} benefits={benefit.benefits}/>
                ))
            }
        </BenefitsSection>
    )
}


export default Benefits;