import React from 'react'
import MainWrapper from '../components/Wrappers'
import MainHeading, {SubHeading} from '../components/PageHeadings'
import Card, { CardImage } from "../components/Card"
import styled from 'styled-components'
import bgImg from "./Images/background.jpg"

const GreenEffectCards = styled.div`
    display: flex;
    gap: 5%;
`;

const GreenEffect = () => {
    return(
        <MainWrapper bgImg={bgImg}>
            <MainHeading>The Green Effect</MainHeading>
            <SubHeading>Stanley aims to be the ‘greenest’ car wash on the planet by eliminating discharge of water to sewer lines and by collecting and then recycling the majority of the water through our patented four chamber filtration system.  Subsequent car washes can use this water during the wash cycle and then rinse the car with fresh water for a sparkling finish.</SubHeading>
                <GreenEffectCards>
                    <Card width="40%" textColor="#003367">
                        <CardImage src="images/graphics/environment.png"></CardImage>
                        <h3>The average person uses 94 gallons of water to wash their car with a hose and bucket</h3>
                        <p>Stanley only uses 15 gallons per wash</p> 
                        <p>Stanley’s soap is 100% Bio Degradable</p>
                    </Card>

                    <Card width="40%" textColor="#003367">
                        <CardImage src="images/graphics/costeffective.png"></CardImage>
                        <h3>Stanley utilizes our unique TPS (Trough, Pump, and Store) System</h3>
                        <p>This system collects all of the water and pumps it into a storage tank for removal</p> 
                        <p>This effectively eliminates ALL of your runoff and no sewer fees!!!</p>
                    </Card>
                </GreenEffectCards>
        </MainWrapper>
        
    )
}
export default GreenEffect;