import React from 'react'
import {Link} from 'react-router-dom';
import styled from 'styled-components'

const StyledNavLogo = styled.div`
    border-radius: 4px;
    padding: 20px;
    &:hover{
        box-shadow: 0 15px 25px rgba(0, 0, 0, 0.4);
    }
    &:active{
        border: 1px solid #96FBF9;
    }
`;
const NavLogo = () => {
    return(
        <StyledNavLogo>
            <Link to="/">
                <img src="images/logos/logoname.png" alt=""/>
            </Link>
        </StyledNavLogo>
    )
}
export default NavLogo;